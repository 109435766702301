exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ref--5-rules-2-0!../../../node_modules/svg-transform-loader/index.js!normalize.css"), "");

// module
exports.push([module.id, ".Polaris-Page {\n  padding: 0 1rem !important;\n  max-width: 100% !important; }\n\n.Polaris-DataTable__Cell {\n  padding: 0.8rem !important;\n  font-size: 1.2rem !important; }\n\n.Polaris-DataTable__Cell--header,\n.Polaris-DataTable__Cell--total {\n  border-left: 1px solid rgba(16, 22, 26, 0.1);\n  font-weight: bold; }\n\n.Polaris-DataTable__TableRow th,\n.Polaris-DataTable__TableRow td {\n  border-left: 1px solid rgba(16, 22, 26, 0.1); }\n\n.Polaris-DataTable__TableRow:nth-child(odd) th,\n.Polaris-DataTable__TableRow:nth-child(odd) td {\n  background-color: rgba(191, 204, 214, 0.2); }\n\nbody {\n  background: #f5f8fa; }\n\n.styles-root-3wLOB {\n  display: block; }\n", "", {"version":3,"sources":["/var/www/b2b-dashboard/src/components/Layout/styles.scss"],"names":[],"mappings":"AACA;EACE,2BAA2B;EAC3B,2BAA2B,EAAE;;AAE/B;EACE,2BAA2B;EAC3B,6BAA6B,EAAE;;AAEjC;;EAEE,6CAA6C;EAC7C,kBAAkB,EAAE;;AAEtB;;EAEE,6CAA6C,EAAE;;AAEjD;;EAEE,2CAA2C,EAAE;;AAE/C;EACE,oBAAoB,EAAE;;AAExB;EACE,eAAe,EAAE","file":"styles.scss","sourcesContent":["@import url(~normalize.css);\n:global .Polaris-Page {\n  padding: 0 1rem !important;\n  max-width: 100% !important; }\n\n:global .Polaris-DataTable__Cell {\n  padding: 0.8rem !important;\n  font-size: 1.2rem !important; }\n\n:global .Polaris-DataTable__Cell--header,\n:global .Polaris-DataTable__Cell--total {\n  border-left: 1px solid rgba(16, 22, 26, 0.1);\n  font-weight: bold; }\n\n:global .Polaris-DataTable__TableRow th,\n:global .Polaris-DataTable__TableRow td {\n  border-left: 1px solid rgba(16, 22, 26, 0.1); }\n\n:global .Polaris-DataTable__TableRow:nth-child(odd) th,\n:global .Polaris-DataTable__TableRow:nth-child(odd) td {\n  background-color: rgba(191, 204, 214, 0.2); }\n\nbody {\n  background: #f5f8fa; }\n\n.root {\n  display: block; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "styles-root-3wLOB"
};