exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".styles-root-n4i43 {\n  width: 100%;\n  max-width: 500px;\n  text-align: center;\n  margin: 50px auto 0; }\n\n.styles-row-16E8c {\n  padding-bottom: 10px; }\n\n.styles-button-2vIbA {\n  width: 50%;\n  margin: 15px auto 0; }\n", "", {"version":3,"sources":["/var/www/b2b-dashboard/src/components/Login/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB,EAAE;;AAExB;EACE,qBAAqB,EAAE;;AAEzB;EACE,WAAW;EACX,oBAAoB,EAAE","file":"styles.scss","sourcesContent":[".root {\n  width: 100%;\n  max-width: 500px;\n  text-align: center;\n  margin: 50px auto 0; }\n\n.row {\n  padding-bottom: 10px; }\n\n.button {\n  width: 50%;\n  margin: 15px auto 0; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "styles-root-n4i43",
	"row": "styles-row-16E8c",
	"button": "styles-button-2vIbA"
};